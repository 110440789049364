import { gql } from '@apollo/client';
import ViewerFields from './ViewerFields.frag';

export default gql`
  query Viewer {
    viewer {
      ...ViewerFields
    }
  }
  ${ViewerFields}
`;
