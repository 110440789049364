import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import { GetServerSideProps, NextPage } from 'next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import AuthLayout from 'components/AuthLayout';
import Button from 'components/Button';
import ClosedEyedIcon from 'components/ClosedEyeIcon';
import FacebookConnectButton from 'components/FacebookConnectButton';
import GoogleConnectButton from 'components/GoogleConnectButton';
import OpenedEyedIcon from 'components/OpenedEyeIcon';
import TextField from 'components/TextField';
import withoutAuth from 'components/withoutAuth';
import {
  AuthenticateUserByEmailInput,
  useAuthenticateUserByEmailMutation,
} from 'generated/graphql';
import { actions as notificationActions } from 'lib/redux/notifications';
import { login } from 'lib/util';
import { cleanOldCookies, shouldCleanCookies } from 'lib/util/cookie.server';
import { email as emailRegex } from 'lib/util/regex';

const { addNotifications } = notificationActions;

const LoginPage: NextPage = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { query, push } = useRouter();

  const redirectTo = query.redirectTo as string;

  const [loadingLogin, setLoadingLogin] = useState<boolean>(false);
  const [passwordShown, setPasswordShown] = useState<boolean>(false);
  const [authenticateUserByEmail, { loading }] = useAuthenticateUserByEmailMutation();
  const { register, formState, handleSubmit } = useForm<AuthenticateUserByEmailInput>({
    mode: 'onBlur',
  });

  const { errors } = formState;

  const onSubmit = handleSubmit(async (data) => {
    try {
      setLoadingLogin(true);
      const response = await authenticateUserByEmail({ variables: { input: data } });

      const token = response.data?.authenticateUserByEmail?.token;
      const viewer = response.data?.authenticateUserByEmail?.viewer;

      if (viewer && token) {
        await login(token);
      }

      setLoadingLogin(false);
      push(redirectTo || '/');
    } catch (err: any) {
      dispatch(addNotifications([{ severity: 'error', message: err.message }]));
      setLoadingLogin(false);
    }
  });

  return (
    <AuthLayout image="/images/man_walking.svg" title="Login">
      <form noValidate onSubmit={onSubmit}>
        <div className="flex flex-col space-y-3">
          <div className="font-medium font-serif text-2xl text-black">
            <FormattedMessage id="login.connect_with" defaultMessage="Connect with" />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-3">
            <FacebookConnectButton redirectTo={redirectTo as string}>
              Facebook
            </FacebookConnectButton>
            <GoogleConnectButton redirectTo={redirectTo as string}>Google</GoogleConnectButton>
          </div>

          <div className="font-medium font-serif text-2xl text-black">
            <FormattedMessage id="login.or" defaultMessage="or" />
          </div>

          <div className="w-full">
            <TextField
              id="email"
              type="email"
              name="email"
              placeholder={intl.formatMessage({
                id: 'login.form.placeholder.email',
                defaultMessage: 'Email',
              })}
              ref={register({
                required: intl.formatMessage({
                  id: 'login.form.validation.email.required',
                  defaultMessage: 'Email is required',
                }),
                pattern: {
                  value: emailRegex,
                  message: intl.formatMessage({
                    id: 'login.form.validation.email.pattern',
                    defaultMessage: 'Email is invalid',
                  }),
                },
              })}
              error={errors.email?.message}
            />
          </div>

          <div className="w-full">
            <TextField
              id="password"
              name="password"
              type={passwordShown ? 'text' : 'password'}
              placeholder={intl.formatMessage({
                id: 'login.form.placeholder.password',
                defaultMessage: 'Password',
              })}
              error={errors.password?.message}
              trailingAddon={
                <div
                  className={clsx(
                    'absolute inset-y-0 right-0 flex items-center',
                    errors.password ? 'pr-10' : 'pr-3'
                  )}
                >
                  <button
                    className="cursor-pointer focus:outline-none"
                    type="button"
                    onClick={() => {
                      setPasswordShown(!passwordShown);
                    }}
                  >
                    {passwordShown ? <OpenedEyedIcon /> : <ClosedEyedIcon />}
                  </button>
                </div>
              }
              ref={register({
                required: intl.formatMessage({
                  id: 'login.form.validation.password.required',
                  defaultMessage: 'Password is required',
                }),
                minLength: {
                  value: 8,
                  message: intl.formatMessage(
                    {
                      id: 'login.form.validation.password.min_length',
                      defaultMessage: 'Password should contain at least 8 characters',
                    },
                    { count: 8 }
                  ),
                },
                validate: {
                  uppercaseLetter: (value) =>
                    !!value.match(/[A-Z]+/) ||
                    intl.formatMessage({
                      id: 'login.form.validation.password.uppercase_letter',
                      defaultMessage: 'Password should contain at least one uppercase letter',
                    }),
                  lowercaseLetter: (value) =>
                    !!value.match(/[a-z]+/) ||
                    intl.formatMessage({
                      id: 'login.form.validation.password.lowercase_letter',
                      defaultMessage: 'Password should contain at least one lowercase letter',
                    }),
                },
              })}
            />
          </div>

          <div className="grid grid-cols-12 gap-3">
            <div className="col-span-12 sm:col-span-6">
              <Button
                type="submit"
                className="min-w-full px-8 btn"
                loading={loading || loadingLogin}
              >
                <FormattedMessage id="login.button.sign_in" defaultMessage="Sign in" />
              </Button>
            </div>

            <div className="col-span-12 sm:col-span-6 flex items-center justify-center">
              <div className="font-medium font-sans text-base text-gray-600 hover:text-black">
                <Link href="/forgot-password">
                  <FormattedMessage
                    id="login.link.forgot_password"
                    defaultMessage="Forgot your password ?"
                  />
                </Link>
              </div>
            </div>
          </div>

          <div className="pt-4">
            <div className="font-medium font-sans text-base text-gray-600">
              <FormattedMessage
                id="login.no_account"
                defaultMessage="Don't have an account ?"
                values={{
                  link: (chunk) => (
                    <Link href="/signup">
                      <span className="underline text-black hover:text-blue-800">{chunk}</span>
                    </Link>
                  ),
                }}
              />
            </div>
          </div>
        </div>
      </form>
    </AuthLayout>
  );
};

export default withoutAuth(LoginPage);

export const getServerSideProps: GetServerSideProps = async ({ req, res }) => {
  // Remove old Colette cookies
  if (shouldCleanCookies(req)) {
    res.setHeader('Set-Cookie', await cleanOldCookies(req));
  }

  return { props: {} };
};
