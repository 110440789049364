const ClosedEyedIcon = (props: React.ComponentProps<'svg'>) => (
  <svg
    width="24"
    height="20"
    viewBox="0 0 24 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.78296 19L21.533 1"
      stroke="#111111"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.96411 17.0509C9.94693 17.3561 10.971 17.5076 12.0001 17.4999C16.1001 17.5689 20.2581 14.6869 22.8241 11.8629C23.0992 11.5575 23.2515 11.161 23.2515 10.7499C23.2515 10.3388 23.0992 9.94231 22.8241 9.63688C21.8945 8.61709 20.8647 7.69344 19.7501 6.87988"
      stroke="#111111"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.4131 4.28195C13.624 4.0856 12.8131 3.99083 12.0001 3.99995C7.96907 3.93295 3.80007 6.75195 1.17907 9.63495C0.903955 9.94038 0.751709 10.3369 0.751709 10.748C0.751709 11.159 0.903955 11.5555 1.17907 11.861C1.976 12.7333 2.84581 13.5363 3.77907 14.261"
      stroke="#111111"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.25 10.75C8.24987 10.2575 8.34678 9.76981 8.53518 9.31478C8.72359 8.85975 8.99981 8.4463 9.34806 8.09806C9.6963 7.74981 10.1098 7.47359 10.5648 7.28518C11.0198 7.09678 11.5075 6.99987 12 7"
      stroke="#111111"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.75 10.749C15.7501 11.2416 15.6532 11.7293 15.4648 12.1844C15.2764 12.6395 15.0002 13.053 14.652 13.4013C14.3038 13.7496 13.8903 14.026 13.4353 14.2145C12.9803 14.403 12.4925 14.5 12 14.5"
      stroke="#111111"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ClosedEyedIcon;
