import { addQueryString } from 'lib/util';

interface Props {
  inviteToken?: string;
  redirectTo?: string;
  disabled?: boolean;
  children: React.ReactNode;
}

const path = '/api/connect/facebook';

const FacebookConnectButton = (props: Props) => {
  const { disabled, children, ...other } = props;
  const url = addQueryString(path, other as Record<string, any>);

  if (process.env.NEXT_PUBLIC_ENABLE_FACEBOOK_CONNECT === 'true') {
    return (
      <a href={url}>
        <button
          type="button"
          className="w-full p-4 rounded-lg font-sans font-medium text-base text-white facebook-button"
          disabled={disabled}
        >
          {children}
        </button>
      </a>
    );
  }

  return null;
};

export default FacebookConnectButton;
