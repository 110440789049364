const OpenedEyedIcon = (props: React.ComponentProps<'svg'>) => (
  <svg
    width="24"
    height="16"
    viewBox="0 0 24 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.0001 1.25096C7.96907 1.18296 3.80007 3.99996 1.17907 6.88496C0.903955 7.19039 0.751709 7.5869 0.751709 7.99796C0.751709 8.40903 0.903955 8.80553 1.17907 9.11096C3.74307 11.935 7.90007 14.817 12.0001 14.748C16.1001 14.817 20.2581 11.935 22.8241 9.11096C23.0992 8.80553 23.2514 8.40903 23.2514 7.99796C23.2514 7.5869 23.0992 7.19039 22.8241 6.88496C20.2001 3.99996 16.0311 1.18296 12.0001 1.25096Z"
      stroke="#111111"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.75 8.00002C15.7498 8.74166 15.5297 9.46659 15.1175 10.0831C14.7054 10.6997 14.1196 11.1802 13.4344 11.4639C12.7491 11.7475 11.9952 11.8217 11.2678 11.6768C10.5404 11.532 9.87234 11.1748 9.348 10.6503C8.82365 10.1258 8.4666 9.45765 8.32198 8.73024C8.17737 8.00284 8.25169 7.2489 8.53555 6.56373C8.81941 5.87857 9.30005 5.29295 9.91672 4.88094C10.5334 4.46893 11.2584 4.24902 12 4.24902C12.4926 4.24889 12.9804 4.34583 13.4355 4.5343C13.8905 4.72277 14.304 4.99908 14.6523 5.34743C15.0006 5.69579 15.2768 6.10935 15.4651 6.5645C15.6535 7.01964 15.7503 7.50745 15.75 8.00002Z"
      stroke="#111111"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default OpenedEyedIcon;
