import clsx from 'clsx';
import Link from 'next/link';
import ColetteLogo from 'components/ColetteLogo';
import Head from 'components/Head';
import { Image } from 'components/Image';
import Notifications from 'components/Notifications';

interface Props {
  title?: string;
  image?: any;
  backgroundImageSrc?: string;
  footer?: React.ReactNode;
  header?: React.ReactNode;
  children: React.ReactNode;
  imageClassName?: string;
}

const AuthLayout = ({
  title,
  image,
  backgroundImageSrc,
  children,
  header,
  footer,
  imageClassName,
}: Props) => (
  <div className="flex flex-wrap w-full">
    <Head title={title} />
    <Notifications />

    <div className="flex-1 overflow-y-auto md:w-1/2">
      <div className="flex flex-col h-screen">
        <div className="pb-6 flex-0 px-9 pt-9 md:px-16 md:pt-12">
          <div className="flex flex-col items-baseline justify-between md:flex-row">
            <div className="grid grid-flow-col grid-cols-12 gap-3 md:grid-cols-none md:auto-cols-max">
              <div className="col-span-full md:col-auto">
                <Link href="/">
                  <ColetteLogo className="col" />
                </Link>
              </div>
            </div>
            {header && <div>{header}</div>}
          </div>
        </div>
        <div className="relative flex-1 py-9">
          <div className="flex items-center h-full px-9 md:px-16">
            <div className="flex flex-col justify-center flex-1">{children}</div>
          </div>
        </div>
        {footer ? (
          <div className="px-12 pb-6 flex-0 pt-9 md:px-16 md:pt-10 md:pb-6">{footer}</div>
        ) : null}
      </div>
    </div>
    <div
      className="flex-1 hidden bg-cover md:inline-flex md:w-1/2"
      style={{
        backgroundImage: backgroundImageSrc && `url(${backgroundImageSrc})`,
      }}
    >
      {image && (
        <div className="relative flex flex-col items-center w-full h-screen bg-blue-500 min-w-min">
          <Image
            className={clsx(imageClassName, 'mx-auto my-auto max-h-full max-w-full object-center')}
            src={image}
            alt=""
            fill
          />
        </div>
      )}
    </div>
  </div>
);

export default AuthLayout;
