import gql from 'graphql-tag';

export default gql`
  fragment ViewerFields on Viewer {
    id
    role {
      id
      name
    }
    roomObserver
    activeBookingCount
    maxActiveBookingCount
    active
    onboarded
    eligible
    hasFacebookIdentity
    hasGoogleIdentity
    notificationSettings {
      smsEnabled
    }
    termsOfServiceAcceptedAt
    profile {
      id
      firstName
      lastName
      completionScore
      email
      emailConfirmedAt
      phoneNumber
      phoneNumberConfirmedAt
      birthdate
      about
      gender
      locale
      linkedin
      nationality
      identityVerified
      avatar {
        url
      }
      video {
        url
        thumbnailUrl
      }
      spokenLanguages {
        english
        french
        german
        italian
        other
        portuguese
        spanish
      }
      workSituation
    }
    compatibilityProfile {
      id
      desiredHousematePersonalityTraits
      houseSharingExpectations
      genderPreference
      homeworkingFrequency
      kitchenUse
      houseSharingReasons
    }
    receivedRecommendationCount
  }
`;
